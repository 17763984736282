import { regex } from '@23people/moonbase-validators-v3';

import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import Page from '../../components/Page';
import TextField from '../../components/text-field';

import Numeric from '../../components/numeric';
import FlexLayout from '../../components/flex-layout';

import TextArea from '../../components/text-area';

import { useForm } from '../../components/form';

import SelectBox from '../../components/select-box';
import SelectRest from '../../components/select-rest';

import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchAll, api } from '../../api';

import './requirements.css';
import Steps from '../../components/steps';
import SelectArray from '../../components/select-array';
import MultiSelectRest from '../../components/multiselect-rest';

export default function Create({ endpoint, labels }) {

  const [country, setCountry] = useState({});
  const [position, setPosition] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [requirementType, setRequirementType] = useState('');
  const [skills, setSkills] = useState([]);
  const [optionalSkills, setOptionalSkills] = useState([]);
  const [optionalSkillsText, setOptionalSkillsText] = useState('');
  const [skillsList, setSkillsList] = useState([]);

  const [salaryStart, setSalaryStart] = useState('');
  const [salaryEnd, setSalaryEnd] = useState('');

  const [workingCondition, setWorkingCondition] = useState('');
  const [englishLevel, setEnglishLevel] = useState('');
  const [duration, setDuration] = useState('');
  const [currency, setCurrency] = useState('');
  const [description, setDescription] = useState('');
  const [publication, setPublication] = useState('');
  const [benefits, setBenefits] = useState([]);

  const [positionLevel, setPositionLevel] = useState('');

  const [customer, setCustomer] = useState('');
  const [manager, setManager] = useState('');
  const [team, setTeam] = useState('');
  const [customerReference, setCustomerReference] = useState('');

  const [managersOptions, setManagersOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);

  const [steps, setSteps] = useState([
    { name: 'Posición', state: 'current' },
    { name: 'Detalles de Publicación' },
    { name: 'Pre-Visualización' }
  ]);

  const [valid, isValid] = useState(false);

  const validForm = !!position && valid; //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm);

  const handleValidation = value => {
    isValid(value.valid);
  };

  const handleSubmit = async () => {
    const req = {
      customer,
      managers: [manager],
      starDate: new Date(),
      requirementType,
      benefits: benefits.map(benefit => benefit.id),
      position,
      positionLevel,
      englishLevel,
      applicantsNumber: quantity,
      country,
      team,
      duration,
      workingCondition,
      salaryRange: `${salaryStart} - ${salaryEnd}`,
      maxSalary: salaryEnd,
      minSalary: salaryStart,
      description,
      publication,
      customerReference,
      currency,
      skills: skills.map(skill => skill.id),
      optionalSkills

    };

    await actions.submitPost(req, 'position');
  };

  const handleCustomerChange = (id, customer) => {

    setTeam('');
    setManager('');
    setCustomer(id)

    const managersWithFullName = customer.managers.map(manager => {
      return {
        ...manager,
        fullname: `${manager.name} ${manager.lastName}`
      }
    })

    setManagersOptions(managersWithFullName);
    setTeamsOptions(customer.teams);
    setCustomerReference(customer.about);
    
  }

  const skillsQuery = useQuery({
    queryKey: [api.skills],
    queryFn: () => fetchAll(api.skills)
  });

  useEffect(() => {
    if (skillsQuery.status === 'success') {
      setSkillsList(skillsQuery.data.result)
    }
  }, [skillsQuery]);


  const handleRequiredSkillsChange = (skillsSelected) => setSkills(skillsSelected);

  const handleOptionalSkillsTextChange = text => {
    setOptionalSkillsText(text);
    const textList = text.split('\n');
    const skills = [];

    textList.forEach(el => {
      //check if el is on skillList
      // if exists then add to list
      // if not exists then do nothing
      const skill = skillsList.find(skill => skill.name === el);

      if (skill) {

        skills.push(skill._id);
      }

    });

    setOptionalSkills(skills);
  }

  if (skillsQuery.isLoading)
    return 'Cargando...';

  if (skillsQuery.isError)
    return 'Ocurrió un error';

  const handleSteps = stepName => {

    // TODO: Refactor this algorithm. Can be a lot simpler
    const newSteps = steps.map(step => {
      return { name: step.name, state: step.state === 'completed' ? 'completed' : '' };
    }
    );

    const index = newSteps.findIndex(step => step.name === stepName);

    newSteps[index] = { name: stepName, state: newSteps[index].state === 'completed' ? 'completed' : 'current' }

    setSteps(newSteps);

  };

  const onChangeBenefits = (items) => {
    setBenefits(items);
  }

  return (
    <Page
      title={labels.title}
      breadcrumb={[
        { label: labels.title, link: labels.link },
        { label: labels.actions.new, link: '' },
      ]}
    >

      <Steps labels={steps} onClick={handleSteps} />

      <CardContainer cssClass='__card-container requirements'>
        {steps[0].state === 'current' ? <main>
          <section className='form-fields'>
            <header>
              <h2>Perfil a Buscar</h2>
              <p>
                Ingresar la descripción el perfil y los campos se seleccionarán automáticamente.
              </p>
            </header>
            <main>

              <TextField
                id='position'
                label='Posición'
                value={position}
                onChange={setPosition}
                onValidation={handleValidation}
                pattern={regex.LATIN_ALPHANUMERIC_SPACE}
                validationText='Por favor ingrese un nombre válido'
                width='100%'
              />

              <FlexLayout>
                <Numeric
                  id='quantity'
                  label='Cantidad'
                  secondLabel='*'
                  required={true}
                  min={0}
                  max={10000}
                  value={quantity.toString()}
                  onChange={setQuantity}
                  locale='es'
                  validationText='Por favor ingrese una cantidad válida'
                  width='96px'
                />

                <SelectRest
                  id='requirementType'
                  label='Tipo'
                  required={true}
                  secondLabel='*'
                  placeholder='Tipo Requerimiento'
                  value={requirementType}
                  onChange={setRequirementType}
                  width='220px'
                  valueField='_id'
                  labelField='name'
                  endpoint={api.requirementTypes}
                />
              </FlexLayout>
            </main>
          </section>

          <section className='form-fields'>
            <header>
              <h2>Cliente</h2>
              <p>
                Seleccione el cliente, manager y equipo para el requerimiento. Si desea agregar
                nuevos managers o equipos de ir al{' '}
                <a href='/customers' className='simple-links'>
                  {' '}
                  Mantenedor de Clientes
                </a>
              </p>
            </header>
            <main>
              <SelectRest
                id='customer'
                label='Cliente'
                required={true}
                requiredText="El campo es requerido"
                secondLabel='*'
                placeholder='Seleccione un cliente'
                value={customer}
                onChange={handleCustomerChange}
                valueField='_id'
                labelField='name'
                endpoint={api.customers}
              />

              <SelectBox
                id='managers'
                label='Manager'
                required={true}
                secondLabel='*'
                placeholder='Seleccione un manager'
                options={managersOptions}
                labelField='fullname'
                valueField='_id'
                value={manager}
                onChange={setManager}
              />

              <SelectArray
                id='teams'
                label='Equipo'
                secondLabel='*'
                placeholder='Seleccione un equipo'
                options={teamsOptions}
                value={team}
                onChange={setTeam}
              />
            </main>
          </section>

          <section className='form-fields'>
            <header>
              <h2>Información sobre la Posición</h2>
              <p>
                Si desea agregar nuevas opciones para los campos debe hacer clic en los siguientes
                links:
                <a href='/working-conditions' className='simple-links'>
                  {' '}
                  Condiciones de Trabajo
                </a>
                ,
                <a href='/english-levels' className='simple-links'>
                  {' '}
                  Nivel de Inglés
                </a>{' '}
                ó
                <a href='/countries' className='simple-links'>
                  {' '}
                  Países
                </a>
                .
              </p>
            </header>
            <main>
              <SelectRest
                id='positionLevel'
                label='Nivel'
                required={true}
                requiredText="El campo es requerido"
                secondLabel='*'
                placeholder='Entry, Mid Level, Senior'
                value={positionLevel}
                onChange={setPositionLevel}
                valueField='_id'
                labelField='name'
                endpoint={api.positionLevels}
              />
              <SelectRest
                id='workingCondition'
                label='Condiciones de Trabajo'
                required={true}
                requiredText="El campo es requerido"
                secondLabel='*'
                placeholder='Remoto, Híbrido o Presencial'
                value={workingCondition}
                onChange={setWorkingCondition}
                valueField='_id'
                labelField='name'
                endpoint={api.workingConditions}
              />

              <SelectRest
                id='english-level'
                label='Nivel Inglés'
                required={true}
                requiredText="El campo es requerido"
                secondLabel='*'
                placeholder='Desde C2 a A1'
                value={englishLevel}
                onChange={setEnglishLevel}
                valueField='_id'
                labelField='name'
                endpoint={api.englishLevels}
              />

              <SelectRest
                id='country'
                label='País de Residencia'
                required={true}
                requiredText="El campo es requerido"
                secondLabel='*'
                placeholder='País desde donde se presta el servicio'
                value={country}
                onChange={setCountry}
                valueField='_id'
                labelField='name'
                endpoint={api.countries}
              />

              <TextField
                id='quantity'
                label='Duración'
                value={duration}
                onChange={setDuration}
                pattern={regex.TEXT}
                placeholder='Duración proyectada: 1 año'
                validationText='Por favor ingrese un nombre válido'
                width='100%'
              />

              <FlexLayout>
                <TextField
                  id='range1'
                  label='Salario mínimo'
                  value={salaryStart}
                  onChange={setSalaryStart}
                  pattern={regex.NUMBER}
                  validationText='Por favor ingrese un número válido'
                  width='160px'
                />
                <TextField
                  id='range2'
                  label='Salario máximo'
                  value={salaryEnd}
                  onChange={setSalaryEnd}
                  pattern={regex.NUMBER}
                  validationText='Por favor ingrese un número válido'
                  width='160px'
                />

                <SelectRest
                  id='currency'
                  label='Moneda'
                  placeholder='CLP, USD, UF'
                  value={currency}
                  onChange={setCurrency}
                  valueField='_id'
                  labelField='name'
                  endpoint={api.currencies}
                />

              </FlexLayout>
            </main>
          </section>

          <section className='form-fields'>
            <header>
              <h2>Conocimientos</h2>
              <p>
                Ingresar la descripción el perfil y los campos se seleccionarán automáticamente.
              </p>
            </header>
            <main>

              <MultiSelectRest
                id="multiSelectskills"
                label="Selecccione una opcion"
                values={skills}
                endpoint={api.skills}
                onChange={handleRequiredSkillsChange}
              />

              <TextArea
                id='optional-skills'
                label='Deseables'
                value={optionalSkillsText}
                onChange={handleOptionalSkillsTextChange}
                pattern={regex.MULTILINE_TEXT}
                validationText='Por favor ingrese un texto válido'
                width='100%'
                rows={10}
              />

            </main>
          </section>

        </main> : ''}

        {steps[1].state === 'current' ? <main>

          <section className='form-fields'>
            <header>
              <h2>Proyecto</h2>
              <p>
                Ingresar la descripción del proyecto o una introducción motivadora.
              </p>
            </header>
            <main>

              <TextArea
                id='projectDescription'
                label='Descripción del proyecto'
                value={description}
                onChange={setDescription}
                pattern={regex.MULTILINE_TEXT}
                validationText='Por favor ingrese un texto válido'
                width='100%'
                rows={10}
              />

            </main>
          </section>

          <section className='form-fields'>
            <header>
              <h2>Publicación</h2>
              <p>
                Ingresar la descripción el perfil y los campos se seleccionarán automáticamente.
              </p>
            </header>
            <main>

              <TextArea
                id='dayToDay'
                label='Día a día'
                value={publication}
                onChange={setPublication}
                pattern={regex.MULTILINE_TEXT}
                validationText='Por favor ingrese un texto válido'
                width='100%'
                rows={10}
              />

              <MultiSelectRest
                id="selectBenefits"
                label="Beneficios"
                placeholder='Seleccione un beneficio'
                values={benefits}
                endpoint={api.benefits}
                onChange={onChangeBenefits}
              />

            </main>
          </section>

        </main> : ''}

        <footer>
          <Button id='btnGuardar' value='Guardar' onClick={handleSubmit} enabled={validForm} />
        </footer>
      </CardContainer>
    </Page>
  );
}
