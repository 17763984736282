import './multiselectrest.css';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import { useState } from 'react';
import Button from '../button';
import SelectRest from '../select-rest';

export default function MultiSelectRest(
  {
    id, 
    label = '', 
    cssClass = '__multiselectrest', 
    onChange = () => {}, 
    placeholder = '',
    endpoint = '',
    values = []
  }) {


  const [showFirstRow, setShowFirstRow] = useState(true);
  const [currentValue, setCurrentValue] = useState({});
  const [activeAdd, setActiveAdd] = useState(false);

  const add = () => {
    setCurrentValue('');

    const item = values.find(el => el.id === currentValue.id);

    if (item !== undefined || !currentValue || currentValue === '') {
      setActiveAdd(false);
      return;
    }

    const setOfChosenItems = new Set([...values, currentValue]);
    const newList = [...setOfChosenItems];

    onChange(newList);
    setActiveAdd(false);
  }

  const remove = selected => {  
    const newList = values.filter(el => el.id !== selected.id);
    onChange(newList);
  }

  const onChangeSelect = (e, options) => {

    const item = {
      id: options._id,
      name: options.name
    }

    setCurrentValue(item);
    setActiveAdd(true);
  }

  const toggleFirstRow = () => {
    setShowFirstRow(!showFirstRow);
  }

  return <section className={cssClass}>
    
    <table id={id} className='divide-y divide-gray-200' width="448px">
      <thead>
        <tr>
          <th width="288px">{label}</th>
          <th width="32px">
            <BsPlusLg size={16} onClick={toggleFirstRow} />
          </th>
        </tr>
      </thead>
      <tbody className='divide-y divide-gray-200'>
        {showFirstRow && <tr>
          <td className='first-row'>

            <SelectRest
              id={id}
              label={label}
              required={false}
              secondLabel='*'
              placeholder={placeholder}
              value={currentValue.id}
              onChange={onChangeSelect}
              width='100%'
              valueField='_id'
              labelField='name'
              endpoint={endpoint}
            />

          </td>
          <td className='first-row'>
            <Button
              id='btnAdd'
              cssClass='__button __circle-button'
              value={<BsPlusLg size={16} />}
              onClick={add}
              enabled={activeAdd}
            />
          </td>
        </tr>
        }
        
        {values.map(element => 
          <tr key={element.id}>
            <td>{element.name}</td>
            <td>
              <Button
                id='btnAdd'
                cssClass='__button __remove-button'
                value={<FaTrashAlt size={14} />}
                onClick={() => remove(element)}
              />
            </td>
          </tr>
        )}
      </tbody>
      <tfoot />
    </table>
    
  </section>;
};